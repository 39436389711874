
// Write your custom style. Feel free to split your code to several files
body {
    min-height:0;
}


.gap{
    height: 500px;
}

.center{
    text-align: center;
}

.footer-stick {
     position: fixed;
    bottom: 0;
    width: 100%;
    text-align: right;
    background-color: transparent;
}
a{
    color: #fff;
}

a:hover{
     color: rgb(251,243,160);
}

p{
    margin-bottom: 0;
}

.h-fullscreen-location{
    height: 165vh !important;
}

.h-fullscreen-subscription{
    height: 130vh !important;
}

.h-fullscreen-about-us{
    height: 110vh !important;
}

.left-bracket{
    position: absolute;
    left: 0;
}

.right-bracket{
    position: absolute;
    right: 0;
}

.title-header{
    display: inline-flex;
}

.curvena-title{
    font-family: 'columbiasans-regular';
    letter-spacing: 8px;
    color:#28486d;
    font-size: 30px;
}

.curvena-desc{
    font-family: 'raleway-regular';
    color:#595959;
    letter-spacing: 1px;
}

.cur-img-title{
    font-family: 'columbiasans-regular';
    color:#2882c5;  
    letter-spacing: 3px;
    font-size: 20px;
    margin: 30px 0px;
}

.float-left{
    float: left;
}

.no-padding{
    padding: 0;
}

.gif-content{
    flex: 0 0 50%;
    max-width: 50%; 
    float: right;
    margin-bottom: -880px;
    margin-top: 300px;
    padding-left: 100px;
}


.sub-title{
        font-family: 'columbiasans-regular';
        letter-spacing: 14px;
        font-size: 20px;
        background-image: linear-gradient(90deg, rgba(251,243,160,1) 21%, rgba(223,182,73,1) 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
}

.slider-arrows-flash-dark .slick-prev, .slider-arrows-flash-dark .slick-next{
    position: absolute;
    top: -30px;
    color: #fff;
        
}

.slider-arrows-flash-dark .slick-prev::before, .slider-arrows-flash-dark .slick-next::before{
    border-right-color: #fff;
    border-bottom-color: #fff;
}

.slider-arrows-flash-dark .slick-prev::after, .slider-arrows-flash-dark .slick-next::after{
  background-color: #fff;  
}

.slick-prev.slick-arrow{
    right:0px;
    left: 450px;
}

.slick-next.slick-arrow{
   left: 500px; 
}

.slick-slide{
margin: 0 10px;
}


.section-header-curvena{
    text-align: center;
    max-width: 90%;
    margin: 0 auto 70px;  
}

.section-curvena-text{
    font-family: 'raleway-medium';
    letter-spacing: 3px;
    text-align: center;
    max-width: 100%;
    margin: 0 auto 40px;
    color:#fff;
}

.curvena-text-center{
    font-family: 'columbiasans-regular';
    letter-spacing: 3px;
    text-align: center;
    max-width: 100%;
}

p.curvena-body-text{
    color: #ffff86;
    margin-bottom: 6px;
}

.curvena-text-raleway p{
    font-family: Raleway;
}

p.curvena-text-columbiasans, .curvena-text-columbiasans{
    font-family: 'columbiasans-regular';
}

.curvena-location-title{
    margin-top: 60px;
    letter-spacing: 3px;
    border-bottom: 3px solid ;
    border-left-width: 0;
    border-right-width: 0;
    border-image: linear-gradient(90deg, rgba(251,243,160,1) 21%, rgba(223,182,73,1) 100%) 1 stretch;
    color:#fff;
    border-top:0;
    border-left:0;
    border-right:0;
}

.curvena-location-text p{
    margin-bottom: 0;
    font-size: 14px;
    letter-spacing: 3px;
    color:#fff;
}

.curvena-social-text{
    font-family: 'columbiasans-regular';
    letter-spacing: 3px;
    margin: 25px 0;
    display: inline-flex;
    font-size: 14px;
    color:#808080;
}

input.curvena-input {
  background-color: #fff;
  color: #000;
  border: none;
  text-align: left;
  border-radius: 25px;
  margin: 10px;
  line-height: 2.5;
  padding-left: 18px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: 'raleway-medium';
  letter-spacing: 2px;
  color: #ccc;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-family: 'raleway-medium';
  letter-spacing: 2px;  
  color: #ccc;
}

::-ms-input-placeholder { /* Microsoft Edge */
  font-family: 'raleway-medium';
  letter-spacing: 2px;    
  color: #ccc;
}

.contact-content{
    display: inline-flex;
    color:#fff;
}

.contact{
    font-family: 'raleway-medium';
    letter-spacing: 2px;
    color:#28486d;
}

.footer-contact{
     margin-top: 120px;
}

.footer-content {
    margin: 80px auto;
    width: 900px;
    position: absolute;
    right: -60px;
}


.btn-grad {
    font-family: 'raleway-medium';
    letter-spacing: 2px;
    background-image: linear-gradient(-149deg, #fff3d0 90%, #fff3d0 110%);
    color:#21406d;
    margin: 20px;
    padding: 14px 90px;
    border:none;
    -moz-box-shadow:    5px 3px 8px 0px #565656;
    -webkit-box-shadow: 5px 3px 8px 0px #565656;
    box-shadow:         5px 3px 8px 0px #565656;
    border-radius: 25px;
}

.btn-consult {
    font-family: 'raleway-medium';
    letter-spacing: 2px;
    background-image: linear-gradient(-149deg, #f7ea88 90%, #b9963a 110%);
    color:#21406d;
    margin: 50px;
    padding: 10px 22px;
    border:none;
    -moz-box-shadow:    9px 7px 12px 2px #565656;
    -webkit-box-shadow: 9px 7px 12px 2px #565656;
    box-shadow:         9px 7px 12px 2px #565656;
    border-radius: 25px 0 0 25px ;
}

.cur-line{
    border: 1px solid #ffff98;
    margin: 10px 0px;
}


@font-face {
     font-family: 'columbiasans-regular';
     src: url("../fonts/columbiasans-regular.otf") format("opentype");
     src: url("../fonts/columbiasans-regular.ttf") format('truetype'); 
     font-weight: normal;
     font-style: normal;
}

@font-face {
     font-family: 'notoSansDemiLight';
     src: url("../fonts/NotoSansDemiLight.otf") format("opentype");
     font-weight: normal;
     font-style: normal;
}

@font-face {
     font-family: 'raleway-regular';
     src: url("../fonts/Raleway-Regular.ttf") format("opentype");
     font-weight: normal;
     font-style: normal;
}

@font-face {
     font-family: 'raleway-medium';
     src: url("../fonts/Raleway-Medium.ttf") format("opentype");
     font-weight: normal;
     font-style: normal;
}

.raleway-regular{
    font-family: 'raleway-regular';
    color:#ffff98;
    font-size: 16px;
    letter-spacing: 2px;
}
.submit-button{
    z-index: -999;
    margin-top: 35px;
}


.curvena-background{
    background-color: #d8d7cb;
}

.display-inline{
    display:inline-block;
}

.hormone-logo{
    width: 650px;
}

.magnet-logo{
    margin-top: 45px;
    width: 290px;
}

.magneto{
    margin-top: 350px;
}




input.curvena-input {
  width: 50%;
}

#banner1-desktop,#slider-desktop,.curvenow-banner-desktop, .desktop{
    display:block;
}

#banner1-mobile,#slider-mobile,.curvenow-banner-mobile, .mobile{
    display:none;
}

@media only screen and (max-width: 3000px) { 
    
    .logo-set{
        width: 900px;
        margin-left: -60px;

    }

    .contact-content{
        margin-top: 30px;
        margin-left: 665px;
    }
    
    .gap{
        height: 120px;
    }
    .gap2{
        height: 180px;
    }
    
}

@media only screen and (max-width: 1200px) { 
   .curvena-curve {
        top: 318px!important;
    }
    input.curvena-input {
        line-height: 2;
    }
    
    .contact-content{
        margin-top: 36px;
        margin-left: 615px;
    }
    
}
@media only screen and (max-width: 992px) { 
   .curvena-curve {
        top: 231px!important;
    }
    input.curvena-input {
        line-height: 1.5;
    }
    
    .submit-button{
        margin-top:0px;
    }
    
    .contact-content{
        margin-top: 30px;
        margin-left: 375px;
        margin-right: -220px;
        font-size: 12px;
    }
    .curvena-social-text{
        font-size: 11px;
    }
}

@media only screen and (max-width: 768px) { 
   .curvena-curve {
        top: 172px!important;
    }
    input.curvena-input {
        line-height: 1.5;
        margin: 6px;
    }
    
    .submit-button{
        margin-top:30px;
    }
    .btn-grad{
        padding: 10px 60px;
    }
    
    .contact-content{
        margin-top: 30px;
        margin-left: 665px;
    }
    
    #banner1-desktop,#slider-desktop,.curvenow-banner-desktop,.desktop{
        display:none;
    }

    #banner1-mobile,#slider-mobile,.curvenow-banner-mobile,.mobile{
        display:block;
    }
}


@media only screen and (max-width: 428px) { 
    
 
    .outlet-text{
        display: inline-flex!important;
        font-size: 7px!important;
        letter-spacing: 1px;
    }
    
    #banner1-desktop,#slider-desktop,.curvenow-banner-desktop,.desktop{
        display:none;
    }

    #banner1-mobile,#slider-mobile,.curvenow-banner-mobile,.mobile{
        display:block;
    }
    
    .gif-mobile{
        padding: 0;
    }
    .slider-arrows-flash-dark{
        //width: 350px!important;
        padding: -2px 30px;
    }
    .slick-list.draggable{
        padding: 0px 10% !important;
    }

    .slick-prev.slick-arrow{
  
        left: 250px;
    }

    .slick-next.slick-arrow{
       left: 300px; 
    }
    
    .curvenow-banner-mobile{
        padding-bottom: 5rem;
    }
    
    .header.h-fullscreen {
        padding-top: 20px;
    }
    
    .bracket{
        height: 48px;
    }
    
    input.curvena-input {
        line-height: 2;
        margin: 12px;
        width: 80%;
    }
    
    .submit-button{
        margin-top:10px;
    }
    
    .curvena-title {
        margin: 11px 12px;
        letter-spacing: 3px;
        font-size: 18px;
        text-align: center;
    }
    
    .curvena-social-text{
        display: block;
        font-size: 10px;
    }
    
    .curvena-outlet{
        display: inline-flex;
        font-size: 8px;
        margin: 10px 0;
        letter-spacing: 1px;
    }
    
    .curvena-curve {
        top: 250px !important;
        left: -2px;
    }
     .btn-grad{
        padding: 18px 80px;
    }
    
    .footer-stick {
        bottom: 30px;
        width: 108%;
        height: 78px;
    }
    
    .contact-content{
        margin-top: 100px;
        margin-left: 35px;
        margin-right: 0px;
    }
    
    .footer-contact{
        margin-top: 150px;
    }
    
    .compulsory{
        font-family: 'raleway-medium';
        color:#21406d;
        letter-spacing: 1px;
        margin-top: 50px;
    }
    
    .logo{
       width: 70%; 
    }
    
    .magnet{
        width: 50%;
    }
    
}

@media only screen and (max-width: 414px) { 
    
    #banner1-desktop,#slider-desktop,.curvenow-banner-desktop,.desktop{
        display:none;
    }

    #banner1-mobile,#slider-mobile,.curvenow-banner-mobile,.mobile{
        display:block;
    }
    
    .curvena-curve {
        top: 240px !important;
    }
    
    .raleway-regular{
        font-size: 10px;
        letter-spacing: 2px;
    }
    
    .gap{
        height: 150px;
    }
}

@media only screen and (max-width: 375px) {
    
    #banner1-mobile{
        padding-top: 0px;
    }
    
    .raleway-regular{
        font-size: 10px;
        letter-spacing: 2px;
    }
    
    #banner1-desktop,#slider-desktop,.curvenow-banner-desktop,.desktop{
        display:none;
    }

    #banner1-mobile,#slider-mobile,.curvenow-banner-mobile,.mobile{
        display:block;
    }
    
    .compulsory{
        margin-top: 40px;
    }
    
    .footer-contact{
        margin-top: 180px;
    }

    .curvena-curve {
        top: 190px !important;
        left: -2px;
    }
    
    .contact-content{
        margin-top: 100px;
        margin-left: 0px;
    }
    
    .imgcur{
        width: 50%;
    }
    
    .curvena-social-text{
         margin: 0px 0;
    }
    .gap{
        height: 100px;
    }
    
    .curvena-title {
        text-align: center;
    }
    
    .logo{
       width: 80%; 
    }
    
    .magnet{
        width: 60%;
    }
    
}

@media only screen and (max-width: 393px) {
    .compulsory{
        margin-top: 32px;
    }
}

@media only screen and (max-width: 320px) { 
    
     .raleway-regular{
        font-size: 8px;
        letter-spacing: 2px;
    }
    
    #banner1-desktop,#slider-desktop,.curvenow-banner-desktop,.desktop{
        display:none;
    }

    #banner1-mobile,#slider-mobile,.curvenow-banner-mobile,.mobile{
        display:block;
    }
    
    .gap{
        height: 90px;
    }
    
     .curvena-title {
        text-align: center;
    }
    .slick-prev.slick-arrow {
        left: 238px;
    }
    .slick-next.slick-arrow {
        left: 280px;
    }
    
    .curvena-curve {
        top: 160px !important;
    }
    
    input.curvena-input {
        margin: 8px;
    }
    
    .btn-grad {
        padding: 10px 80px;
    }
    
    .callus{
        margin-left: 1rem !important;
    }
}

.card .raleway-medium{
   font-family: 'raleway-medium';
}

.accordion-connected{
    border: none;
    text-align: left;
    font-family: 'raleway-medium';
}
.accordion .card, .accordion .card-title{
    background-color: transparent;
}
.accordion .card-title{
    padding-left: 0px;
    padding-right: 0px;
}
.accordion .card-title a{
    color:#ffff98;
}
.accordion-connected .card{
    border-bottom: 1px solid #ffff98 !important;
}
.accordion .card-title a::before{
  border-left: 1px solid #ffff98;
  border-top: 1px solid #ffff98;
}

.curvena-curve{
    position: absolute;
    top: 385px;

    width: 100%;
}

@media only screen 
    and (device-width : 375px) 
    and (device-height : 812px) 
    and (-webkit-device-pixel-ratio : 3) { 

     .gap{
        height: 200px;
    }
}